:root {
  --background-secondary: #f4f3ef;
  --background-secondary-darker: #dedece;
  --text-grey: rgb(0 0 0 / 54%);
}

html {
  box-sizing: border-box;
  font-size: 0.625em;
  scroll-behavior: smooth;
}
* {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  background: rgb(255, 254, 252);
}

body {
  font-size: 1.6rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.2;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
button {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  padding: 0;
  border: 0;
  background: none;
}

.pac-container {
  font-family: inherit;
  box-shadow: none;
  padding: 0.1rem 0;
  background: none;
}
.pac-item {
  color: rgb(0 0 0 / 54%);
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  background: var(--background-secondary);
  border: 0;
  cursor: pointer;
}
.pac-icon {
  display: none;
}
.pac-item-query {
  color: black;
  font-size: 1.8rem;
  margin-right: 0.3rem;
}
.pac-item-selected,
.pac-item:hover {
  background: var(--background-secondary-darker);
}
.pac-matched {
  font-weight: 700;
}
